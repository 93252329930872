<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <path
      style="fill:#FF6991;"
      d="M455.111,379.259H18.963C8.489,379.259,0,370.77,0,360.296V75.852
	c0-10.473,8.489-18.963,18.963-18.963h436.148c10.473,0,18.963,8.489,18.963,18.963v284.444
	C474.074,370.77,465.585,379.259,455.111,379.259z"
    />
    <path
      style="fill:#FF507D;"
      d="M379.259,312.889c-20.946,0-37.926,16.98-37.926,37.926v28.444h113.778
	c10.472,0,18.963-8.491,18.963-18.963v-47.407H379.259z"
    />
    <path
      style="fill:#FFC850;"
      d="M142.222,199.111H66.37c-5.236,0-9.481-4.245-9.481-9.481v-56.889c0-5.236,4.245-9.481,9.481-9.481
	h75.852c5.236,0,9.481,4.245,9.481,9.481v56.889C151.704,194.866,147.458,199.111,142.222,199.111z"
    />
    <path
      style="fill:#E4EAF8;"
      d="M407.704,334.222H56.889c-6.546,0-11.852-5.306-11.852-11.852c0-6.546,5.306-11.852,11.852-11.852
	h350.815c6.546,0,11.852,5.306,11.852,11.852C419.556,328.916,414.25,334.222,407.704,334.222z"
    />
    <path
      style="fill:#E1A546;"
      d="M123.259,151.704h-18.963v-28.444H85.333v75.852h18.963v-28.444h18.963
	c5.236,0,9.481-4.245,9.481-9.481C132.741,155.949,128.495,151.704,123.259,151.704z"
    />
    <circle style="fill:#FF507D;" cx="317.63" cy="156.444" r="42.667" />
    <circle style="fill:#FFC850;" cx="374.519" cy="156.444" r="42.667" />
    <path
      style="fill:#FF8C66;"
      d="M331.852,156.444c0,12.587,5.553,23.784,14.222,31.593c8.67-7.809,14.222-19.007,14.222-31.593
	c0-12.587-5.553-23.784-14.222-31.593C337.404,132.66,331.852,143.858,331.852,156.444z"
    />
    <g>
      <path
        style="fill:#E4EAF8;"
        d="M113.778,277.333H56.889c-6.546,0-11.852-5.306-11.852-11.852s5.306-11.852,11.852-11.852h56.889
		c6.546,0,11.852,5.306,11.852,11.852S120.324,277.333,113.778,277.333z"
      />
      <path
        style="fill:#E4EAF8;"
        d="M407.704,277.333h-56.889c-6.546,0-11.852-5.306-11.852-11.852s5.306-11.852,11.852-11.852h56.889
		c6.546,0,11.852,5.306,11.852,11.852S414.25,277.333,407.704,277.333z"
      />
      <path
        style="fill:#E4EAF8;"
        d="M309.732,277.333h-56.889c-6.546,0-11.852-5.306-11.852-11.852s5.306-11.852,11.852-11.852h56.889
		c6.546,0,11.852,5.306,11.852,11.852S316.277,277.333,309.732,277.333z"
      />
      <path
        style="fill:#E4EAF8;"
        d="M211.75,277.333h-56.889c-6.546,0-11.852-5.306-11.852-11.852s5.306-11.852,11.852-11.852h56.889
		c6.546,0,11.852,5.306,11.852,11.852S218.297,277.333,211.75,277.333z"
      />
    </g>
    <path
      style="fill:#D5DCED;"
      d="M345.307,334.222h62.396c6.546,0,11.852-5.306,11.852-11.852c0-3.982-2.092-7.333-5.103-9.481
	h-35.193C364.294,312.889,351.476,321.632,345.307,334.222z"
    />
    <path
      style="fill:#E1A546;"
      d="M502.519,341.333h-18.963v-56.889c0-26.139-21.268-47.407-47.407-47.407
	s-47.407,21.268-47.407,47.407v56.889h-18.963v-56.889c0-36.593,29.778-66.37,66.37-66.37s66.37,29.778,66.37,66.37V341.333z"
    />
    <path
      style="fill:#FFDC64;"
      d="M493.037,455.111H379.259c-10.474,0-18.963-8.489-18.963-18.963v-85.333
	c0-10.473,8.489-18.963,18.963-18.963h113.778c10.473,0,18.963,8.489,18.963,18.963v85.333
	C512,446.622,503.511,455.111,493.037,455.111z"
    />
    <path
      style="fill:#464655;"
      d="M455.111,384c0-10.473-8.491-18.963-18.963-18.963c-10.472,0-18.963,8.489-18.963,18.963
	c0,7.001,3.838,13.048,9.481,16.333v10.271c0,5.241,4.241,9.481,9.482,9.481c5.241,0,9.481-4.241,9.481-9.481v-10.271
	C451.273,397.048,455.111,391.001,455.111,384z"
    />
    <path
      style="fill:#FFC850;"
      d="M398.222,398.222v-66.37h-18.963c-10.474,0-18.963,8.489-18.963,18.963v85.333
	c0,10.473,8.489,18.963,18.963,18.963h113.778c10.472,0,18.963-8.491,18.963-18.963h-75.852
	C415.202,436.148,398.222,419.168,398.222,398.222z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
